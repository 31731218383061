import React from "react";
import { Typography } from "@material-ui/core";

const Projects = () => (
  <div>
    <Typography variant="h4" gutterBottom style={{textAlign: 'center'}}>Projects:</Typography>
    <codersrank-portfolio username="andydarknessb"></codersrank-portfolio>
 
  </div>
);

export default Projects;
